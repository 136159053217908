import React from "react";
import "./index.css";
import logo from "./assets/IMG_4749.jpeg"; // Import the image
import { FaTelegram, FaDiscord, FaTwitter, FaInstagram, FaDollarSign } from "react-icons/fa";
import { MdWeb } from "react-icons/md";

function App() {
  const redirectToPumpFun = () => {
    window.location.href = "https://pump.fun/coin/FUuTmP46s8d5EvuvBcwoXuXRRCPVN3hWonjr7fdXpump";
  };

  return (
    <div>
      {/* Navigation Bar */}
      <nav className="top-nav">
        <a href="https://t.me/+v0TMl_3n7RthZWJh" target="_blank" rel="noreferrer">
          <FaTelegram size={24} />
        </a>
        <a href="https://discord.gg/sRQgD8aVqH" target="_blank" rel="noreferrer">
          <FaDiscord size={24} />
        </a>
        <a href="https://twitter.com/zuckyyycrypto" target="_blank" rel="noreferrer">
          <FaTwitter size={24} />
        </a>
        <a href="https://www.instagram.com/zuckysol/" target="_blank" rel="noreferrer">
          <FaInstagram size={24} />
        </a>
        <a href="https://pump.fun/coin/FUuTmP46s8d5EvuvBcwoXuXRRCPVN3hWonjr7fdXpump" target="_blank" rel="noreferrer">
          <FaDollarSign size={24} />
        </a>
      </nav>

      {/* Hero Section */}
      <header className="hero">
        <img
          src={logo}
          alt="Zucky Coin Logo"
          style={{
            width: "150px",
            borderRadius: "50%",
            marginBottom: "20px",
            border: "3px solid white",
          }}
        />
        <h1>Zucky Coin</h1>
        <p>The ultimate memecoin—join the revolution today!</p>
        <div className="button-container">
          <button
            onClick={redirectToPumpFun}
            className="pump-fun-button"
          >
            <FaDollarSign size={20} style={{ marginRight: "10px" }} />
            BUY ON PUMP.FUN!
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container">
        <section className="section">
          <h2>What is Zucky?</h2>
          <div className="card">
            <p>
              Zucky is not just another crypto—it’s a cultural movement powered by memes, fun, and a passionate community. Get in on the action while it’s hot!
            </p>
          </div>
        </section>
        <section className="section">
          <h2>Our Roadmap</h2>
          <div className="card">
            <h3>Phase 1: Launch</h3>
            <p>Release the Zucky token, build hype, and recruit early adopters to the community.</p>
          </div>
          <div className="card">
            <h3>Phase 2: Expansion</h3>
            <p>Grow social media channels, form strategic partnerships, and launch viral marketing campaigns.</p>
          </div>
          <div className="card">
            <h3>Phase 3: To the Moon!</h3>
            <p>List Zucky on top exchanges, expand utility, and build the Zucky ecosystem to dominate the crypto space.</p>
          </div>
        </section>
        <section className="section">
          <h2>Join the Movement</h2>
          <div className="card">
            <p>Follow us on social media, share your memes, and connect with other Zuckheads around the world!</p>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer>
        <p>© 2024 Zucky Coin.</p>
      </footer>
    </div>
  );
}

export default App;